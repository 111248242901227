import { registerApplication, start } from "single-spa";
import * as isActive from "./activity-functions";

registerApplication(
  "@demo/single-spa-demo-nav",
  () => System.import("@demo/single-spa-demo-nav"),
  isActive.nav,
  { domElement: document.getElementById("nav-container") }
);

registerApplication(
  "@demo/sidebar",
  () => System.import("@demo/sidebar"),
  isActive.nav,
  { domElement: document.getElementById("sidebar-container") }
);

registerApplication(
  "@demo/pedidos",
  () => System.import("@demo/pedidos"),
  isActive.pedidos,
  { domElement: document.getElementById("page-1-pedidos") }
);

registerApplication(
  "@demo/productos",
  () => System.import("@demo/productos"),
  isActive.productos,
  { domElement: document.getElementById("page-2-productos") }
);

start();
